var creditCalculator = document.getElementById('creditCalculator');
if (typeof (creditCalculator) != 'undefined' && creditCalculator != null) {

  var procentInMonth = 0;
  var coefAnuitetPay = 0;
  var monthAnuitetPay = 0;
  var totalPereplata = 0;

  var stavka = 36;
  var period = 0;
  var creditAmout = 0;
  var clientPay = 0;

  var stavki = [
    [200000, 15],
    [300000, 9],
    [301000, 36]
  ];

  var stavkiCount = stavki.length;

// load a locale
  numeral.register('locale', 'ua', {
    delimiters: {
      thousands: ' ',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    // ordinal : function (number) {
    //     return number === 1 ? 'er' : 'ème';
    // },
    currency: {
      symbol: 'грн'
    }
  });

// switch between locales
  numeral.locale('ua');

  var periodSlider = document.getElementById('slider-period');
  var amountSlider = document.getElementById('slider-amount');
  var payamountSlider = document.getElementById('slider-payamount');

// Период
  noUiSlider.create(periodSlider, {
    start: 12,
    step: 1,
    format: wNumb({
      decimals: 0, // default is 2
    }),
    range: {
      min: 1,
      max: 60
    },
    // pips: {
    //     mode: 'values',
    //     values: [3, 80],
    //     density: 6
    // }
  });

// Сума кердиту
  noUiSlider.create(amountSlider, {
    start: 500000,
    step: 1000,
    format: wNumb({
      decimals: 0, // default is 2
    }),
    range: {
      min: 1000,
      max: 500000
    }
  });

// Перший внесок
  noUiSlider.create(payamountSlider, {
    start: 0,
    step: 1000,
    format: wNumb({
      decimals: 0, // default is 2
    }),
    range: {
      min: 0,
      max: 500000
    }
  });


  var inputPeriodValue = document.getElementById('slider-period-value');
  var inputAmountValue = document.getElementById('slider-amount-value');
  var inputPayAmountValue = document.getElementById('slider-payamount-value');

//spans result
  var resultCreditAmount = document.getElementById('calc-amount');
  var resultPeriod = document.getElementById('calc-period');
  var resultOnePayCommission = document.getElementById('calc-commission');
  var resultPayAmountInMonth = document.getElementById('calc-month-pay');
  var resultPereplata = document.getElementById('calc-pereplata');
  var resultTotal = document.getElementById('calc-total');
  var resultStavka = document.getElementById('calc-stavka');


  period = inputPeriodValue.value;
  creditAmout = inputAmountValue.value;
  clientPay = payamountSlider.value;


  periodSlider.noUiSlider.on('update', function (values, handle) {
    period = values[handle];
    inputPeriodValue.value = values[handle];
    resultPeriod.innerHTML = values[handle];
    calculate();
  });

  inputPeriodValue.addEventListener('change', function () {
    period = this.value;
    periodSlider.noUiSlider.set(this.value);
    calculate();
  });

  amountSlider.noUiSlider.on('update', function (values, handle) {
    creditAmout = values[handle];
    inputAmountValue.value = values[handle];
    resultCreditAmount.innerHTML = numeral(values[handle]).format('0,0');
    calculate();
  });

  inputAmountValue.addEventListener('change', function () {
    creditAmout = this.value;
    amountSlider.noUiSlider.set(this.value);
    calculate();
  });

  payamountSlider.noUiSlider.on('update', function (values, handle) {
    clientPay = values[handle];
    inputPayAmountValue.value = values[handle];
    calculate();
  });

  inputPayAmountValue.addEventListener('change', function () {
    clientPay = this.value;
    payamountSlider.noUiSlider.set(this.value);
    calculate();
  });

}


function round(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

// Calculate Results
function calculate() {

  //UI variable
  var UIamount = document.getElementById('calc-amount').innerText;
  var creditAmountUAH = parseFloat(UIamount) * 1000;

  var UIinterest = searchSatavka(parseInt(UIamount));
  var UIyears = document.getElementById('calc-period').innerText / 12;
  var UImonthlyPayment = document.getElementById('calc-month-pay');

  var principal = parseFloat(UIamount);
  var calculatedInterest = parseFloat(UIinterest) / 100 / 12;
  var calculatedPayments = parseFloat(UIyears) * 12;

  //complate monthly payment
  var x = Math.pow(1 + calculatedInterest, calculatedPayments);
  var monthly = (principal * x * calculatedInterest) / (x - 1) * 1000;

  var totalPayment = (monthly * calculatedPayments);
  var totalInterest = ((monthly * calculatedPayments) - principal);

  resultPayAmountInMonth.innerText = round(monthly);
  resultTotal.innerText = round(totalInterest);
  resultPereplata.innerText = round(parseFloat(totalPayment) - creditAmountUAH);
  resultStavka.innerText = UIinterest;

}


function searchSatavka(amount) {
  for (var i = 0; i < stavkiCount; i++) {
    var stavkaProcent = stavki[i][1];
    if (parseInt(amount) * 1000 <= parseInt(stavki[i][0])) {
      return stavkaProcent;
    }
    if (i >= stavkiCount - 1) {
      return stavki[stavkiCount - 1][1];
    }
  }
}
