var mapIdOnPage = document.getElementById('map');
if (typeof (mapIdOnPage) != 'undefined' && mapIdOnPage != null) {

    var map;
    var markers = [];
    var filters = {atm: false, office: false, terminal: false};

    const zoomLevelToMarker = 16;
    const currentLang = window.location.pathname.split('/')[1];

    $(function () {
        $('input[name=filter]').change(function (e) {
            map_filter(this.id);
            filter_markers()
        });
    });

    var get_set_options = function () {
        let ret_array = [];
        for (option in filters) {
            if (filters[option]) {
                ret_array.push(option)
            }
        }
        return ret_array;
    };

    var filter_markers = function () {
        let set_filters = get_set_options();

        // for each marker, check to see if all required options are set
        let mapset;
        for (i = 0; i < markers.length; i++) {
            let marker = markers[i];
            let keep = true;
            mapset = map;
            for (opt = 0; opt < set_filters.length; opt++) {
                if (!marker.properties[set_filters[opt]]) {
                    keep = false;
                }
            }
            marker.setVisible(keep)
        }
    };

    var map_filter = function (id_val) {
        filters[id_val] = !filters[id_val];
    };


    function loadMarkers() {
        let infoWindow = new google.maps.InfoWindow();
        let geojson_url = '/offices/json';
        let params = new window.URLSearchParams(window.location.search);
        if (params.get('microcredyt')) {
            geojson_url = '/offices/json?microcredyt=1';
        }

        $.getJSON(geojson_url, function (result) {

            data = result;
            $.each(data, function (key, val) {
                //console.log(key, val);
                // console.log(parseFloat(val['latitude']), parseFloat(val['longitude']));
                if (val['latitude'] && val['longitude']) {
                    let point = new google.maps.LatLng(
                        parseFloat(val['latitude']),
                        parseFloat(val['longitude'])
                    );


                    let titleText = val['title'];
                    let servicesTxt = val['services'];
                    let addressTxt = val['address'];
                    let phonesTxt = val['phones'];
                    let workdaysTxt = val['worktime'];
                    let holidaysTxt = val['holidays'];
                    let icon = '/images/i-map.svg';


                    if (val['type'] === 'office') {
                        icon = '/images/i-map.svg';
                    } else if (val['type'] === 'atm') {
                        titleText = 'Банкомат';
                        icon = '/images/atm.svg';
                    } else if (!val['type'] === 'terminal') {
                        titleText = 'ПТКС';
                    }

                    if (servicesTxt) {
                        servicesTxt = '<li><i class="fa-li fa fa-handshake-o"></i> ' + servicesTxt + "</li>";
                    } else {
                        servicesTxt = '';
                    }

                    if (addressTxt) {
                        addressTxt = '<li><i class="fa-li fa fa-map-marker"></i> ' + addressTxt + '</li>';
                    } else {
                        addressTxt = '';
                    }

                    if (phonesTxt) {
                        phonesTxt = '<li><i class="fa-li fa fa-phone"></i> ' + phonesTxt + '</li>';
                    } else {
                        phonesTxt = '';
                    }

                    if (workdaysTxt) {
                        workdaysTxt = '<li><i class="fa-li fa fa-clock-o"></i> ' + workdaysTxt + '</li>';
                    } else {
                        workdaysTxt = '';
                    }

                    if (holidaysTxt) {
                        holidaysTxt = '<li><i class="fa-li fa fa-coffee"></i> ' + holidaysTxt + '</li>';
                    } else {
                        holidaysTxt = '';
                    }

                    let marker = new google.maps.Marker({
                        position: point,
                        title: titleText,
                        // animation:google.maps.Animation.BOUNCE,
                        icon: icon,
                        map: map,
                        properties: val['services']
                    });
                    let markerInfo = '<div class="map-info"><div class="map-info-title">' + titleText + '</div><ul class="fa-ul">' + addressTxt + phonesTxt + workdaysTxt + holidaysTxt + servicesTxt + '</ul></div>';
                    marker.addListener('click', function () {
                        if (map.getZoom() < zoomLevelToMarker) {
                            map.setZoom(zoomLevelToMarker);
                        }
                        map.panTo(marker.position);
                        infoWindow.close();
                        infoWindow.setContent(markerInfo);
                        infoWindow.open(map, marker)
                    });
                    markers.push(marker)

                }

            });
        });
    }

    var styles = {
        default: null,
        hide: [
            {
                featureType: 'poi.business',
                stylers: [{visibility: 'off'}]
            },
            {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{visibility: 'off'}]
            }
        ]
    };


    function initMap() {
        map_options = {
            panControl: true,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: true,
            overviewMapControl: true,
            rotateControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },
            zoom: 7,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: {lat: 49.31079888, lng: 31.69555664}
        };
        map_document = document.getElementById('map');
        map = new google.maps.Map(map_document, map_options);
        loadMarkers();

        map.setOptions({styles: styles['hide']});
    }

    google.maps.event.addDomListener(window, 'load', initMap);
}
