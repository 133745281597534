window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('bootstrap-cookie-alert/cookiealert');
    require('owl.carousel2/dist/owl.carousel');
    window.numeral = require('numeral/numeral');
    require('numeral/locales/uk-ua');
    require('mmenu-js');
    require('jquery-smooth-scroll');
    require('bootstrap-select');
    window.wNumb = require('wnumb');
    window.noUiSlider = require('nouislider');
} catch (e) {
    // console.log(e);
}

$(document).ready(function () {

    if(window.top.location.hash == ''){
        $(this).scrollTop(0);
    }

    $(".toogleArchive").on("click", (function () {
        $("#fileArchive").toggle()
    }));
    $("a.to-order-form").on("click", (function () {
        return $.smoothScroll({scrollTarget: "#order-form"});
    }));
    $("a.to-more").on("click", (function () {
        return $.smoothScroll({scrollTarget: "#more"});
    }));
    $("a.to-terms").on("click", (function () {
        return $.smoothScroll({scrollTarget: "#terms"});
    }));
    $("a.to-docs").on("click", (function () {
        return $.smoothScroll({scrollTarget: "#docs"});
    }));


        $('.collapse').on('shown.bs.collapse', function (e) {
            var $card = $(this).closest('.card');
            $('html,body').animate({
                scrollTop: $card.offset().top - 100
            }, 100);
        });

        //Фильтр банкоматов и отделений в списке
        $("#filter-select").change(function () {
            var selectedEventType = this.options[this.selectedIndex].value;
            if (selectedEventType === "all") {
                $('.office').removeClass('hidden');
            } else {
                $('.office').addClass('hidden');
                $('.office[data-eventtype="' + selectedEventType + '"]').removeClass('hidden');
            }
        });


        //Меню гамбургер
        $('#nav-menu').mmenu(
            {
                language: 'ru',
                offCanvas: true,
                openingInterval: 0,
                extensions: [
                    // "fullscreen",
                    // "position-back",
                    'position-right',
                    'pagedim-black',
                    // "position-bottom"
                ],
                navbar: {
                    sticky: false,
                    title: 'CIB.COM.UA'
                }
            }
        );

        var api = $('#nav-menu').data('mmenu');
        api.bind('open:start', function () {
            document.body.style.marginTop = '0';
        });
        api.bind('close:start', function () {
            document.body.style.marginTop = '100px';
        });


        //Карусель для продуктов
        let slidesCount = 4;
        let getSlidesCount = $('.product-selector').data('count');
        if (getSlidesCount !== undefined && getSlidesCount < slidesCount) {
            slidesCount = getSlidesCount;
        }

        $('.product-carousel').owlCarousel({
            items: slidesCount,
            responsiveClass: false,
            drag: true,
            dots: true,
            nav: false,
            responsive : {
                0 : {
                    items:1,
                },
                700 : {
                    items:2,
                },
                1000 : {
                    items: 3,
                },
                1200 : {
                    items: slidesCount,
                }
            }
        });

        $('.page-slider').owlCarousel({
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            center: true,
            dots: true,
            nav: false,
            items: 1,
            loop: true,
            responsiveClass: false,
            drag: true,

        });

        $('.page-slider-mobile').owlCarousel({
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            center: true,

            autoHeight: true,
            items: 1,
            loop: true,
            drag: true,

        });


        //Добавлеяет всем таблица адаптивность
        $("table").addClass("table-responsive");

        //Отправка форм
        $('#sendFeedback').click(function () {
            $('#sendFeedback').prop('disabled', true);
            $('#sendFeedback').text('Відправка...');
            $('#feedbackForm').submit();
            $('#sendFeedback').hide();
            $('#falseBtn').removeClass('d-none');
            return true;
        });

        $('#sendFeedbackEmailPhone').click(function () {
            $('#sendFeedbackEmailPhone').prop('disabled', true);
            $('#sendFeedbackEmailPhone').text('Пошук...');
            $('#searchFeedback').submit();

            return true;
        });

        $('#sendFeedbackNumber').click(function () {
            $('#sendFeedbackNumber').prop('disabled', true);
            $('#sendFeedbackNumber').text('Пошук...');
            $('#searchFeedbackNumber').submit();
            return true;
        });

        $('#sendReview').click(function () {
            $('#sendReview').prop('disabled', true);
            $('#sendReview').text('Відправка...');
            $('#reviewForm').submit();
            return true;
        });

    $('#sendAnonim').click(function () {
        $('#sendAnonim').prop('disabled', true);
        $('#sendAnonim').text('Відправка...');
        $('#anonimForm').submit();
        return true;
    });


        // смена банеров на страничке продукта с мобильных на полноформатные и наоборот
        function adjustStyle(width) {
            width = parseInt(width);
            var bg = $('.intro').css('background-image');
            if (bg) {
                bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");

            if (width < 1000) {
                let baner = $('.intro').attr('data-mobile-banner');
                baner = `url("${baner}")`;
                if (baner !== bg) {
                    $('.intro').attr('style', `background-image: ${baner}`);
                }
            } else {
                let baner = $('.intro').attr('data-full-banner');
                baner = `url("${baner}")`;
                if (baner !== bg) {
                    $('.intro').attr('style', `background-image: ${baner}`);
                }
            }
            }
        }

        $(function () {
            adjustStyle($(window).width());
            $(window).resize(function () {
                adjustStyle($(this).width());
            });
        });

    adjustStyle($(window).width());
    });


//
// $(window).on('resize', function () {
//     $('.page-slider').trigger('refresh.owl.carousel');
// });
//
// $(window).on('load', function () {
//     $('.page-slider').trigger('refresh.owl.carousel');
// });
